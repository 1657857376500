.how-steelify-works-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vw auto;
  width: 80%;
}

.how-steelify-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
}

.how-steelify-left {
  flex: 1;
  padding: 20px;
}

.how-steelify-left h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.how-steelify-left p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.how-steelify-right {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.steelify-video {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
}

.steelify-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;
}

.steelify-column {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.steelify-column h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.steelify-column p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

.toggle-button {
  color: blue;
  cursor: pointer;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .how-steelify-content {
    flex-direction: column;
    align-items: center;
  }

  .steelify-columns {
    flex-direction: column;
    align-items: center;
  }

  .steelify-column {
    margin-bottom: 20px;
  }

  .toggle-button {
    display: inline;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .how-steelify-left,
  .how-steelify-right {
    padding: 10px;
  }

  .how-steelify-left h2 {
    font-size: 24px;
  }

  .steelify-video {
    max-width: 400px;
  }

  .steelify-columns {
    margin-top: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .how-steelify-left,
  .how-steelify-right {
    padding: 10px 15px;
  }

  .steelify-video {
    max-width: 450px;
  }
}

@media (min-width: 1200px) {
  .how-steelify-left,
  .how-steelify-right {
    padding: 10px 20px;
  }

  .steelify-video {
    max-width: 500px;
  }
}
