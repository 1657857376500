.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    padding: 20px;
}

.forgot-password-card {
    width: 100%;
    max-width: 400px; /* Increased max width for the card */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.forgot-password-form {
    margin-top: 16px;
}

.forgot-password-button {
    width: 100%;
}
