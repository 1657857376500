.faq-page {
    background-color: #f9f9f9;
  }
  
  .faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
  }
  
  .faq-title {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .faq-category {
    margin-bottom: 30px;
  }
  
  .faq-category-title {
    border-left: 5px solid #1890ff;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  
  .faq-item .ant-collapse-header {
    font-weight: bold;
  }
  
  .faq-item .ant-collapse-content p {
    margin: 0;
  }