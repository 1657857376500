.chat-content {
  height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-message-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
}

.chat-message {
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  display: inline-block;
  max-width: 60%;
  word-wrap: break-word;
}

.chat-message.user2 {
  background-color: #e6f7ff;
  justify-content: flex-start;
}

.chat-message.user1 {
  background-color: #f6ffed;
  justify-content: flex-end;
  margin-left: auto;
}

.message-bubble {
  border-radius: 5px;
  word-break: break-word;
}

.timestamp {
  font-size: 0.8em;
  color: gray;
  margin-top: 5px;
  text-align: right;
  
}
