.trustpilot-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
  
  .trustpilot-container a {
    display: inline-block;
    text-decoration: none;
  }
  
  .trustpilot-image {
    border: none;
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  