.certification-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 14px;
    color: #000;
    gap: 8px;
    flex-wrap: wrap;
    text-align: center;
}

.certification-icon {
    font-size: 16px;
    color: #000;
}

.certification-container span {
    font-size: 14px;
    color: #000;
    text-align: center;
}

@media (max-width: 768px) {
    .certification-container {
        flex-direction: column;
    }
}
