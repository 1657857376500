.homepage-container {
    background-color: #f6f8fb;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-left: 7%;
    padding-right: 7%;
}

.header-and-quote {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.quote-form-section {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-section {
    flex: 1;
    margin-top: 7rem;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certification-container {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .header-and-quote {
        flex-direction: column;
    }

    .quote-form-section,
    .header-section {
        width: 100%;
        margin-top: 20px;
    }

    .header-section {
        order: 1;
    }

    .quote-form-section {
        order: 2;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .header-and-quote {
        justify-content: space-around;
    }
}

/* Varsayılan olarak her ikisini de gizle */
.web, .mobile {
    display: none;
  }
  
  /* Masaüstü (web) için */
  @media only screen and (min-width: 768px) {
    .web {
      display: block; /* Web görünümü büyük ekranlarda gösterilecek */
    }
  }
  
  /* Mobil için */
  @media only screen and (max-width: 767px) {
    .mobile {
      display: block; /* Mobil görünümü küçük ekranlarda gösterilecek */
    }
  }
  