.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    padding: 20px;
}

.reset-password-card {
    width: 100%;
    max-width: 360px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}



.reset-password-button {
    width: 100%;
}
