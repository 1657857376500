.completed-works-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sectors {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sectors h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.sectors p {
  font-size: 16px;
  margin-bottom: 10px;
}

.sectors ul {
  list-style: none;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectors li {
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sectors li.active-sector {
  background-color: #007bff;
  color: white;
}

.sectors li:hover {
  background-color: #e0e0e0;
}

.image-display {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-display img {
  width: 600px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FF6F00;
  border: none;
  cursor: pointer;
  padding: 15px;
  font-size: 18px;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: white;
}

.prev-button {
  left: -5px;
}

.next-button {
  right: -5px;
}

.nav-button:hover {
  background-color: #FF8A00;
}

@media (max-width: 768px) {
  .completed-works-container {
    flex-direction: column;
    align-items: center;
  }

  .sectors {
    width: 100%;
    margin-bottom: 20px;
  }

  .image-display {
    width: 100%;
    max-width: 90vw;
  }

  .image-display img {
    width: 100%;
    height: auto;
  }

  .prev-button {
    left: -10px;
  }

  .next-button {
    right: -10px;
  }
}
