.pdf-viewer-container {
    margin-left: 24px;
    margin-top: 125px;
}

.pdf-thumbnail {
    width: 150px;
    height: 200px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: f6f8fb;
}
