html, body, * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.site-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-layout-content {
  /* padding-left: 7%;
  padding-right: 7%; */
  flex-grow: 1;
  background-color: #f6f8fb;
}

.footer {
  background-color: #39414d;
  padding: 20px 50px;
}
