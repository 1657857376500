.cnc-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10vh;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #d6d8db;
}

.cnc-left {
  flex: 1 1 50%;
}

.cnc-right {
  flex: 1 1 30%;
  margin-left: 20vh;
}

.cnc-left {
  padding: 20px 40px 40px 40px;
  display: flex;
  flex-direction: column;
}

.cnc-left h1 {
  margin-top: 7vh;
  margin-bottom: 3vh;
  font-size: 36px;
  color: #333;
}

.cnc-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cnc-content p {
  margin-bottom: 20px;
  font-size: 18px;
  color: #555;
}

.cnc-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.cnc-content ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

.capabilities-trustpilot-container {
  width: 80%;
}

.cnc-call-to-action {
  margin-top: 20px;
}

.call-to-action-text {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 1vh;
}

.call-to-action-button {
  display: inline-block;
  background-color: #FF6F00;
  color: #fff;
  text-decoration: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  width: fit-content;
}

.call-to-action-button:hover {
  background-color: #e65c00;
}

.cnc-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cnc-right img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .cnc-left,
  .cnc-right {
    flex: 1 1 100%;
  }

  .cnc-left {
    padding: 20px;
  }

  .cnc-container {
    margin-left: 5%;
    margin-right: 5%;
  }
}
