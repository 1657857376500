.get-quote-form {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
}

.get-quote-form .ant-form-item {
  margin-bottom: 20px;
}

.get-quote-form textarea {
  resize: none;
}

.get-quote-form .ant-btn-primary {
  width: 100%;
  border-color: #007bff;
  position: relative;
}

.get-quote-form .ant-btn-primary:hover {
  border-color: #0056b3;
}

.submit-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.quote-switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.quote-text {
  margin-right: 10px;
}

.upload-security {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  color: #555;
  margin-top: 10px;
}

.lock-icon {
  margin-right: 5px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .quote-switch-container {
    justify-content: flex-end;
  }

  .quote-text {
    display: inline;
  }

  .ant-upload-hint {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .quote-switch-container {
    justify-content: flex-end;
  }

  .quote-text {
    display: inline;
  }
}
