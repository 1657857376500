.navbar-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #f6f8fb;
}

.navbar-logo {
  cursor: pointer;
  width: 15rem;
  margin-left: 3.5rem;
  transition: width 0.3s ease;
}

.navbar-menu {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  background-color: #f6f8fb;
  border-bottom: none;
  margin: 1rem;
  font-size: 1.05rem;
  line-height: 4rem;
}

.navbar-menu-button {
  background-color: #f6f8fb;
  border: none;
  color: black;
}

@media (max-width: 768px) {
  .navbar-flex-container {
    justify-content: center;
    position: relative;
    padding: 0 10px;
  }
  .navbar-logo {
  display: none;
  }

  .navbar-menu-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .navbar-menu {
    margin: 0.5rem;
  }
}

@media (max-width: 992px) {
  .navbar-logo {
    width: 120px;
    margin-bottom: 1rem;
  }
  .navbar-menu {
    margin: 0.75rem;
  }
}
