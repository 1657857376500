.chat-sidebar {
    width: 200px;
    border-right: 3px solid #d9d9d9; /* Thicker border */
    overflow-y: auto;
    margin-top: 15px;
    padding-right: 10px;
  }
  
  .chat-sidebar-content {
    padding: 0;
    background-color: #fffaf2; /* Slightly different light orange */
  }
  
  .chat-sidebar-item {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .chat-sidebar-item:hover {
    background-color: #ff9500; /* Brighter orange for hover */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  }
  
  .chat-sidebar-item.active {
    background-color: #ff9500; /* Brighter orange for active chat */
  }
  
  .ant-list-item {
    padding: 0;
  }
  
  .ant-list-item-meta {
    margin: 0;
  }
  