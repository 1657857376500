.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .cookie-buttons {
    display: flex;
    gap: 10px;
  }
  