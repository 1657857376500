.header-content {
  display: inline-block;
  text-align: center;
}

.header-content h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
}

.header-content .highlight {
  color: #FF6F00;
}

.header-content p {
  font-size: 1.5em;
  color: #555;
  line-height: 1.5em;
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .header-content {
    margin-bottom: 6rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .header-content {
    margin-bottom: 6rem;
  }
}

@media (max-width: 767px) {
  .header-content h1 {
    margin-bottom: 7rem;
    margin-top: 5rem;
  }
  .header-content p {
    margin-bottom: 7rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .header-content h1 {
    margin-bottom: 5rem;
  }
  .header-content p {
    margin-bottom: 2rem;
    width: 40%;
  }
}