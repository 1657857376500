.get-quote-page-container {
  background-color: #f6f8fb;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-quote-card {
  width: 100%;
  max-width: 1200px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin: 0 auto;
}

.get-quote-spin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-quote-form {
  width: 100%;
  padding: 0;
  border-radius: 8px;
}

.get-quote-form .ant-form-item {
  margin-bottom: 20px;
  padding: 0;
}

.get-quote-form textarea {
  resize: none;
}

.get-quote-form .ant-btn-primary {
  width: 100%;
  border-color: #007bff;
  position: relative;
}

.get-quote-form .ant-btn-primary:hover {
  border-color: #0056b3;
}

.submit-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

@media (max-width: 768px) {
  .quote-switch-container {
    justify-content: space-between;
  }

  .quote-text {
    display: inline;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .quote-switch-container {
    justify-content: flex-end;
  }

  .quote-text {
    display: inline;
  }
}
