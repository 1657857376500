.mobile-logo-container {
    display: flex;
    justify-content: center;
  }
  
  .mobile-logo {
    width: 100px;
  }
  
  @media only screen and (max-width: 768px) {
    .mobile-logo {
      margin-top: 17vh;
    }
  }
  