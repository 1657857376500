.cookie-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .cookie-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    color: #000;
  }
  
  .cookie-preference {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
  }
  
  .cookie-preference label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #000;
  }
  
  .cookie-preference span {
    margin-left: 10px;
  }
  
  .cookie-preference p {
    margin: 0 0 10px 0;
    font-size: 0.9em;
    color: #666;
  }
  
  .cookie-modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  