.chat-input {
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.chat-input .ant-input {
  flex: 1;
  margin-right: 10px;
}

.chat-icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
}

.chat-icon:nth-child(2) {
  right: 45px;
}
