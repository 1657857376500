.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 10rem 0 5rem 0;
  padding: 0 10%;
  gap: 20px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(50% - 20px);
  position: relative;
  max-width: 300px;
  min-height: 20vh;
}

.stat-icon-container {
  width: 4.5rem;
  height: 4.5rem;
  background-color: #f6f8fb;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3rem;
}

.stat-item:nth-child(1) .stat-icon-container,
.stat-item:nth-child(3) .stat-icon-container {
  background-color: #FF6F00;
}

.stat-item:nth-child(2) .stat-icon-container,
.stat-item:nth-child(4) .stat-icon-container {
  background-color: #0E6AED;
}

.stat-icon {
  width: 1.75rem;
  height: 1.75rem;
}

.stat-content {
  margin-top: 2rem;
}

.stat-number {
  font-size: 1.25rem;
  font-weight: 700;
  color: #333;
}

.stat-label {
  font-size: 0.875rem;
  color: #666;
}

@media (max-width: 768px) {
  .stat-item {
    flex: 1 1 calc(50% - 10px);
    padding: 0%;
  }

  .stat-item:nth-child(3),
  .stat-item:nth-child(4) {
    margin-top: 3rem;
  }
  .stats-container {
    margin: 2rem 0 2rem 0;
  }
}

@media (min-width: 768px) {
  .stat-item {
    flex: 1 1 calc(25% - 20px);
  }
}

@media (min-width: 1024px) {
  .stat-item {
    flex: 1 1 calc(25% - 20px);
    min-height: 10vh;
  }
}

@media (min-width: 1200px) {
  .stat-item {
    flex: 1 1 calc(25% - 20px);
    min-height: 13vh;
  }
}