.landing-page-header {
  position: relative;
  background-image: url('./assets/steelifyBackgroundImage.png');
  background-size: cover;
  background-position: center;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* Adjust the text color based on your image */
}

.landing-page-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(85, 80, 80, 0.5);
  z-index: -1;
}

.title, .subtitle {
  position: relative;
  z-index: 2;
}

.title {
  color: white !important;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 16px;
}

.subtitle {
  color: #d9d9d9;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  text-align: center;
}
