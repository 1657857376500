.map-container {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 7vh;
  padding-left: 7%;
  padding-right: 7%;
}

.leaflet-map {
  width: 100%;
  height: 700px;
}

.map-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.legend {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.customers {
  background-color: #FF6F00;
}

.suppliers {
  background-color: #0E6AED;
}

/* Mobil Cihazlar İçin */
@media (max-width: 768px) {
  .leaflet-map {
    height: 400px;
  }

  .map-title {
    font-size: 1.5rem;
  }

  .legend {
    flex-direction: column;
    align-items: center;
  }

  .legend-item {
    margin-bottom: 10px;
    margin-right: 0;
  }
}
