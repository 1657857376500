.capabilities-container {
  background: linear-gradient(90deg, #f6f8fb 0%, #83a7ef 680%);
  width: 80%;
  max-width: 1000px;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.capabilities-title {
  position: absolute;
  top: -80px;
  left: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.capability-item {
  width: 12%;
  text-align: center;
  margin-top: -60px;
}

.capability-icon-container {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
}

.capability-icon {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.capability-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  white-space: normal;
}

@media (max-width: 768px) {
  .capabilities-container {
    width: 100%;
    padding: 20px;
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .capability-item {
    width: 20%;
    margin: 10px 2.5%;
  }

  .capability-icon-container {
    padding: 8px;
  }

  .capability-title {
    font-size: 12px;
    white-space: normal;
  }

  .capabilities-title {
    font-size: 20px;
    top: -30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .capabilities-container {
    width: 90%;
    padding: 25px;
    margin-top: 10rem;
    margin-bottom: 5rem;
  }

  .capability-item {
    width: 20%;
    margin: 10px 1%;
  }

  .capabilities-title {
    font-size: 22px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .capabilities-container {
    width: 85%;
    padding: 25px;
    margin-top: 10rem;
    margin-bottom: 5rem;
  }

  .capability-item {
    width: 16%;
  }

  .capabilities-title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .capabilities-container {
    width: 80%;
    padding: 30px;
    margin-top: 10rem;
    margin-bottom: 5rem;
  }

  .capability-item {
    width: 12%;
  }

  .capabilities-title {
    font-size: 24px;
  }
}
