.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-title-container {
  display: flex;
  align-items: center;
}

.chat-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 9rem;
}
.chat-order-name {
  font-size: 14px;
  font-weight: normal;
}

.chat-toggle-icon {
  font-size: 18px;
  cursor: pointer;
}
