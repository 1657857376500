.chat-widget-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-card {
  width: 800px;
  height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chat-body {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.chat-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
}

.chat-bar {
  width: 150px;
  height: 50px;
  background-color: #1890ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.chat-bar-text {
  font-size: 16px;
  font-weight: bold;
}

.chat-input .ant-input {
  flex: 1;
  margin-right: 10px;
}
