.process-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  position: relative;
}

.process-image {
  width: 100%;
  max-width: 1000px;
  height: auto;
  border-radius: 10px;
}

@media (min-width: 769px) {
  .process-image {
    transform: translateX(-60px);
  }
}

@media (max-width: 768px) {
  .process-image {
    transform: translateY(-50px);
    max-width: 100%;
  }
}
