.request-actions {
    margin-bottom: 20px;
  }
  
  .button-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .single-button-row {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .action-button {
    flex: 1;
    height: 40px;
    background-color: #ff6600;
    border-color: #ff6600;
    color: #fff;
    font-size: 14px;
  }
  
  .action-button:hover {
    background-color: #e65c00;
    border-color: #e65c00;
  }
  